import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Bar from '../components/Bar'
import Legs from '../components/Legs'
import Main from '../components/Main'
import CompetitionLink from '../components/CompetitionLink'

export default function Match({ data: { match } }) {
  return (
    <Layout>
      <SEO
        title={`${match.competition.name} ${match.season}`}
        description={`Endspiel im ${match.competition.name} in der Saison ${match.season}`}
      />
      <Bar color="--primary-800">
        <h2>
          <CompetitionLink competition={match.competition}>
            {match.competition.name}
          </CompetitionLink>
        </h2>
      </Bar>
      <Bar color="--primary-700">
        <h3>Saison {match.season}</h3>
      </Bar>
      <Main>
        <h4>
          {match.winner.team} – {match.runnerUp.team} ({match.result})
        </h4>

        <Legs legs={match.legs} />
      </Main>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    match(slug: { eq: $slug }) {
      id
      season
      result
      winner {
        team
        country
      }
      runnerUp {
        team
        country
      }
      legs {
        result
        city
        stadium
        location {
          lat
          lng
        }
      }
      competition: parent {
        ... on Competition {
          id
          name
        }
      }
    }
  }
`
