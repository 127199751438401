import React from 'react'
import styled from 'styled-components'

const MapStyles = styled.a`
  display: inline-block;

  img {
    display: inline-block;
    object-fit: cover;
  }
`

export default function Map({
  location,
  width = 300,
  height = 300,
  zoom = 14,
  style = 'satellite-streets-v11',
  user = 'mapbox',
}) {
  return (
    <MapStyles
      href={`https://www.google.de/maps/place/@${location.lat},${location.lng},14z`}
      target="_blank"
    >
      <img
        src={`https://api.mapbox.com/styles/v1/${user}/${style}/static/${location.lng},${location.lat},${zoom}/${width}x${height}?access_token=${process.env.GATSBY_MAPBOX_TOKEN}`}
        width={width}
        height={height}
        alt={`Karte bei Koordinaten ${location.lng},${location.lat}`}
      />
    </MapStyles>
  )
}
