import React from 'react'
import styled from 'styled-components'

import Map from './Map'

const LegsStyles = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`

export default function Legs({ legs }) {
  if (legs.length === 1) {
    return (
      <LegsStyles>
        <div>
          <p>
            {legs[0].stadium} in {legs[0].city}
          </p>
          <Map location={legs[0].location} />
        </div>
      </LegsStyles>
    )
  }

  return (
    <LegsStyles>
      {legs.map((leg, index) => (
        <div>
          <p>
            {index === 0 ? 'Hinspiel' : 'Rückspiel'}: {leg.stadium} in{' '}
            {leg.city} {leg.result}
          </p>
          <Map location={leg.location} />
        </div>
      ))}
    </LegsStyles>
  )
}
